exports.components = {
  "component---src-components-article-js": () => import("./../../../src/components/article.js" /* webpackChunkName: "component---src-components-article-js" */),
  "component---src-pages-fondazione-js": () => import("./../../../src/pages/fondazione.js" /* webpackChunkName: "component---src-pages-fondazione-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-progetti-js": () => import("./../../../src/pages/progetti.js" /* webpackChunkName: "component---src-pages-progetti-js" */),
  "component---src-pages-sostieni-js": () => import("./../../../src/pages/sostieni.js" /* webpackChunkName: "component---src-pages-sostieni-js" */),
  "component---src-pages-talk-js": () => import("./../../../src/pages/talk.js" /* webpackChunkName: "component---src-pages-talk-js" */)
}

